<template>
    <header class="header">
        <AuthLogo />
    </header>
</template>

<script>
import AuthLogo from '@/Icons/auth-form/AuthLogo.vue'

export default {
    components: {AuthLogo},
}
</script>

<style lang="scss" scoped>
.header {
    @apply flex justify-center pb-[8px] w-full border-b-[1px] border-[--separator-color-2];
}
</style>
